import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import { useQuery, useLazyQuery, useApolloClient, gql } from "@apollo/client";

import ProductPostTemplate from "../templates/product";
import { FurnitureFragment } from "../fragments/furniture";

// const PREVIEW_ID_QUERY = gql`
//   query PostPreviewQuery($id: Int!) {
//     postBy(postId: $id) {
// query PostPreviewQuery {
const PREVIEW_ID_QUERY = gql`
  query PostPreviewQuery($id: ID!) {
    post(id: $id) {
      revisions(last: 1, before: null) {
        nodes {
          title
          content
        }
      }
    }
  }
`;
// furniture {
//   ...FurnitureFragment
// }
// ${FurnitureFragment}
// TODO: create types that can handle null

const PREVIEW_SLUG_QUERY = gql`
  query PostTemplatePreviewQuery($slug: String!) {
    postBy(slug: $slug) {
      revisions(last: 1, before: null) {
        nodes {
          title
          content
        }
      }
    }
  }
`;

function Preview({ pageContext, location }) {
  // function Preview(props) {
  // const [post, setPost] = useState(null);
  // const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);
  // const { location } = props;
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const secret = searchParams.get("secret");
  // console.log(id, secret);

  // TODO:
  // implementation:
  // 1. phase: no-auth graphql querrying
  // 2. phase: jwt graphql querries

  // console.log(parseInt(id));

  // const [execute, { called, error, data }] = useLazyQuery(PREVIEW_ID_QUERY, {
  // const { loading, error, data } = useQuery(PREVIEW_ID_QUERY, {
  const { loading, error, data } = useQuery(PREVIEW_SLUG_QUERY, {
    // variables: { id: parseInt(id) },
    variables: { slug: "Kl-1" },
    fetchPolicy: "network-only",
  });

  // if (loading) return <Loading />;
  if (error) return <p>ERROR: {error.message}</p>;
  if (data === undefined) return <p>ERROR</p>;
  // return <div>{JSON.stringify(data)}</div>;

  // const slug = searchParams.get("slug");
  // const wpnonce = searchParams.get("wpnonce");
  // console.log(slug, wpnonce);
  // const context = {
  //   headers: {
  //     "X-WP-Nonce": wpnonce,
  //   },
  //   credentials: "include",
  // };

  // const { loading, error, data } = useQuery(PREVIEW_SLUG_QUERY, {
  //   context,
  //   variables: { slug },
  // });

  // console.log(loading, error, data);
  // console.log(called, error);
  // console.log(loading, error);
  // console.log(data);
  // console.log(pageContext);

  const checkSecret = async () => {
    const envSecret = await process.env.GATSBY_WP_PREVIEW_SECRET;
    // console.log(envSecret);
    // console.log(typeof envSecret);
    // console.log(secret);
    // console.log(typeof secret);
    // console.log(secret !== envSecret);
    if (secret !== envSecret) {
      navigate("/404/");
    }
  };

  useEffect(() => {
    checkSecret();
  });

  // useEffect(() => {
  //   if (!called) {
  //     execute();
  //     console.log(data);
  //   }
  // });

  // // if (error !== null) {
  // if (error) {
  //   return <span>{error}</span>;
  // }

  // // if (loading) {
  // if (!called) {
  //   return <span>Loading...</span>;
  // }

  return (
    <ProductPostTemplate
      pageContext={pageContext}
      location={"/preview/"}
      data={{}}
      // data={{ preview: data?.postBy?.revisions?.nodes[0] }}
    />
  );
}

export default Preview;
